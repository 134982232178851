/*global document*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    var submitElements = document.querySelectorAll('.js-submit-on-change');

    if (submitElements) {
        for (var i = 0; i < submitElements.length; i++) {
            var watchElements = submitElements[i].getElementsByClassName('js-submit-on-change-watch');


            for (var j = 0; j < watchElements.length; j++) {
                var formToSubmit = submitElements[i];
                watchElements[j].addEventListener('change', function (e) {
                    if (this.classList.value.includes('js-sorting-dropdown-select')) {
                        this.closest('.js-sorting-form').querySelector('#orderBy').removeAttribute('name');
                    }

                    formToSubmit.submit();
                })
            }
        }
    }
});
